import { useDeepCompareEffect } from '@fuse/hooks';
import HookedVirtualizedChipSelect from 'app/shared-components/Hooked/HookedVirtualizedChipSelect';
import { useGetAllFleetsByCompaniesQuery } from 'app/store/api/fleetsSlice';
import { TFleetType } from 'app/store/api/types';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { splitAndGetOption } from '../../../helper';
import { TFormData } from '../filterSchema';

interface FleetSelectProps {
	initialValue: string;
	sx?: {};
	className?: string;
	fullWidth?: boolean;
	noHelperText?: boolean;
}

export const FleetSelect: React.FC<FleetSelectProps> = ({ initialValue, sx, fullWidth = true, className, noHelperText }) => {

	const { control, setValue, watch } = useFormContext<TFormData>();
	const { t } = useTranslation('eventsReportPage');

	const selectedCompanies = watch('company');
	const hasCompanySelected = !_.isEmpty(selectedCompanies);
	const companiesIds = _.map(selectedCompanies, '_id');
	const skip = !hasCompanySelected;

	const { fleets, isFleetsLoading } = useGetAllFleetsByCompaniesQuery(companiesIds, {
		selectFromResult: ({ data, ...res }) => ({
			fleets: data?.docs || [],
			isFleetsLoading: res.isLoading
		}),
		skip
	});

	useDeepCompareEffect(() => {
		if (!_.isEmpty(fleets) && initialValue) {
			setValue('fleet', splitAndGetOption(initialValue, fleets));
		}
	}, [initialValue, fleets]);

	return (
		<HookedVirtualizedChipSelect<TFormData, TFleetType>
			fullWidth={fullWidth}
			className={className}
			sx={sx}
			options={fleets}
			control={control}
			name="fleet"
			label={t('FLEETS')}
			optionLabel="name"
			t={t}
			loading={isFleetsLoading}
			isDisabled={!hasCompanySelected}
			limitText={(n) => t('FLEETS_SELECTED', { n })}
			maxListHeight={200}
			noHelperText={noHelperText}
		/>
	);
};
