import { useDeepCompareEffect } from '@fuse/hooks';
import { TCompany } from 'app/main/map/types';
import HookedVirtualizedChipSelect from 'app/shared-components/Hooked/HookedVirtualizedChipSelect';
import { useGetCompaniesQuery } from 'app/store/api/apiSlice';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { splitAndGetOption } from '../../../helper';
import { TFormData } from '../filterSchema';

type CompaniesSelectProps = {
	initialValue: string;
	sx?: {};
	className?: string;
	fullWidth?: boolean;
	noHelperText?: boolean;
};

export const CompaniesSelect: React.FC<CompaniesSelectProps> = ({ initialValue, sx, fullWidth = true, className, noHelperText }) => {

	const { t } = useTranslation('eventsReportPage');
	const { control, setValue } = useFormContext<TFormData>();

	const { companies, isCompaniesLoading } = useGetCompaniesQuery('', {
		selectFromResult: ({ data, ...res }) => ({
			companies: data?.docs || [],
			isCompaniesLoading: res.isLoading
		})
	});

	const handleChangeCompany = () => {
		setValue('fleet', []);
		setValue('vehicle', []);
		setValue('tracker', []);
	};

	useDeepCompareEffect(() => {
		if (!_.isEmpty(companies) && initialValue) {
			setValue('company', splitAndGetOption(initialValue, companies));
		}
	}, [companies, initialValue]);

	return (
		<HookedVirtualizedChipSelect<TFormData, TCompany>
			fullWidth={fullWidth}
			className={className}
			sx={sx}
			options={companies}
			control={control}
			name="company"
			onChangeCallback={handleChangeCompany}
			label={t('COMPANIES')}
			optionLabel="name"
			t={t}
			loading={isCompaniesLoading}
			limitText={(n) => t('COMPANIES_SELECTED', { n })}
			maxListHeight={200}
			noHelperText={noHelperText}
		/>
	);
};
