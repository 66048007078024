import { FleetSearchParams, TFleet } from 'app/main/fleet/types';
import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';
import { apiSlice } from './apiSlice';
import getStringQuery from './getStringQuery';
import { IApiPagination, TPaginatedQuery } from './types';

export function getFleetStringQuery(paginatedData: FleetSearchParams, baseUrl: string) {
	const queryArgs = {
		company: paginatedData.company || null,
		vehicle: paginatedData.vehicle || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `${getStringQuery(paginatedData, baseUrl)}&${query.toString()}`;
}
export interface IEditMultipleFleetPayload {
	ids: string;
}

export type IFleetPayload = {
	id: string;
	name: string;
	notes?: string;
	company: string;
};
export const usersSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllFleets: builder.query<IApiPagination<TFleet>, TPaginatedQuery>({
			query: (paginatedData: TPaginatedQuery) => ({
				url: getFleetStringQuery(paginatedData, '/fleet')
			}),
			providesTags: ['Fleet']
		}),
		getFleet: builder.query({
			query: (id: string) => `/fleet/${id}`,
			providesTags: (__, _, id) => [{ type: 'Fleet', id }]
		}),
		updateFleet: builder.mutation({
			query: (fleetData) => ({
				url: `/fleet/${fleetData.id}`,
				method: 'PUT',
				body: fleetData
			}),
			invalidatesTags: ['Fleet']
		}),
		updateMultiple: builder.mutation<string, IEditMultipleFleetPayload>({
			query: (fleetData) => ({
				url: `/fleet/list?ids=${fleetData.ids}`,
				method: 'PUT',
				body: fleetData
			}),
			invalidatesTags: ['Fleet']
		}),
		createFleet: builder.mutation<string, IFleetPayload>({
			query: (fleetData) => ({
				url: '/fleet',
				method: 'POST',
				body: fleetData
			}),
			invalidatesTags: ['Fleet']
		}),
		deleteFleets: builder.mutation({
			query: (ids?: string) => ({
				url: `/fleet/list?ids=${ids}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Fleet']
		}),
		getAllFleetsByCompanies: builder.query<IApiPagination<TFleet>, string[]>({
			query: (company: string[]) => ({
				url: '/fleet',
				method: 'GET',
				params: {
					limit: 0,
					...(company?.length > 0 && { company })
				}
			}),
			providesTags: ['Fleet']
		})
	})
});

export const {
	useGetAllFleetsQuery,
	useGetFleetQuery,
	useUpdateFleetMutation,
	useCreateFleetMutation,
	useDeleteFleetsMutation,
	useUpdateMultipleMutation,
	useGetAllFleetsByCompaniesQuery
} = usersSlice;
