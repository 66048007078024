import {
	Autocomplete,
	AutocompleteRenderOptionState,
	CircularProgress,
	InputAdornment,
	TextField,
	TextFieldProps
} from '@mui/material';
import _ from 'lodash';
import { UseControllerProps, useController } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import ListboxComponent from './ListBoxComponent';

export type THookedChipSelect<T> = {
	options: T[];
	optionLabel: string;
	label: string;
	placeholder?: string;
	loading?: boolean;
	limitText?: (n: number) => string;
	fullWidth?: boolean;
	isDisabled?: boolean;
	t: TFunction;
	color?: 'primary' | 'secondary';
	size?: 'small' | 'medium';
	groupBy?: string;
	isMultiple?: boolean;
	maxListHeight?: string | number;
	renderOption?: (
		_props: React.HTMLAttributes<HTMLLIElement>,
		_option: T,
		_state: AutocompleteRenderOptionState
	) => React.ReactNode;
	infoText?: string;
	filterOptions?: (options: T[], state: { inputValue: string }) => T[];
	onChangeCallback?: (_value: T) => void;
	noOptionsText?: string;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	className?: string;
	noHelperText?: boolean;
};

type TProps<F, T> = UseControllerProps<F> & TextFieldProps & THookedChipSelect<T>;

// only used when the value of the SelectButton is an array of strings
export function getSelectedOptions(newValue: string, current: string[]) {
	const currentIndex = current.indexOf(newValue);
	const newChecked = [...current];

	if (currentIndex === -1) {
		newChecked.push(newValue);
	} else {
		newChecked.splice(currentIndex, 1);
	}
	return newChecked;
}

export default function HookedVirtualizedChipSelect<F, T>({
	options = [],
	optionLabel,
	label,
	placeholder,
	loading = false,
	fullWidth = false,
	isDisabled = false,
	limitText = undefined,
	name,
	size = 'small',
	color = 'secondary',
	groupBy = undefined,
	t,
	control,
	isMultiple = true,
	required = false,
	maxListHeight = undefined,
	renderOption = undefined,
	infoText = undefined,
	onChangeCallback,
	noOptionsText,
	filterOptions,
	sx = {},
	isOptionEqualToValue,
	className,
	noHelperText = false

}: TProps<F, T>) {
	const {
		field,
		formState: { errors }
	} = useController({
		name,
		control
	});
	const handleChange = (_, val: T) => {
		field.onChange(val);
		onChangeCallback && onChangeCallback(val);
	};

	// Commented out because it was causing issues with the form validation
	// useEffect(() => {
	// 	if (_.isEmpty(field.value) && _.size(options) === 1) {
	// 		field.onChange([options[0]]);
	// 	}
	// }, [options, field]);

	const hasError = _.has(errors, name);
	const error = hasError ? _.get(errors, [name, 'message']) : undefined;
	const helperText = hasError && t(error);

	return (
		<Autocomplete
			className={className}
			renderOption={renderOption}
			color={color}
			ChipProps={{ color }}
			multiple={isMultiple}
			disablePortal
			openOnFocus
			id={name}
			size={size}
			options={options}
			loading={loading}
			limitTags={0}
			filterOptions={filterOptions}
			noOptionsText={noOptionsText}
			getOptionLabel={(option) => _.toString(option[optionLabel]) || option._id}
			renderInput={(params) => (
				<TextField
					className={className}
					{...params}
					InputLabelProps={{ required }}
					variant="outlined"
					label={label}
					placeholder={placeholder}
					fullWidth={fullWidth}
					name={name}
					helperText={noHelperText ? undefined : helperText || infoText}
					error={_.has(errors, name)}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<InputAdornment position="start">
										<CircularProgress color="secondary" size={18} />
									</InputAdornment>
								) : (
									params.InputProps.endAdornment
								)}
							</>
						)
					}}
				/>
			)}
			onBlur={field.onBlur}
			value={field.value}
			onChange={handleChange}
			ref={field.ref}
			fullWidth={fullWidth}
			disabled={isDisabled}
			isOptionEqualToValue={isOptionEqualToValue || _.isEqual}
			getLimitTagsText={limitText}
			disableCloseOnSelect
			groupBy={groupBy ? (opt) => opt[groupBy] : undefined}
			ListboxComponent={ListboxComponent}
			ListboxProps={{
				style: {
					padding: 0,
					maxHeight: maxListHeight,
					overflowY: 'hidden'
				}
			}}
			sx={sx}
		/>
	);
}
