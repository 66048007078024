import FusePageSimple from '@fuse/core/FusePageSimple';
import { Box } from '@mui/system';
import useHistoryPaginationQuery from 'app/services/hooks/useHistoryPagination';
import usePermission from 'app/services/hooks/usePermission';
import NoPermission from 'app/shared-components/NoPermission';
import { useGetTrackerEventsQuery } from 'app/store/api/apiSlice';
import { useGetAllHistoryQuery } from 'app/store/api/historySlice';
import { selectSideBarOpen, setSideBarOpen } from 'app/store/reports/historySlice';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from 'src/utils/useLocalStorage';
import { HISTORY_PERMISSIONS } from './HistoryConfig';
import Content from './components/Content';
import Header from './components/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { availableSortKeys, columnsSearchKeys, defaultVisibleColumns, getColumns } from './tableColumns';
import { THistoryPosition } from './types';

const emptyArr = [];

export default function History() {
	const { t } = useTranslation('historyPage');
	const dispatch = useDispatch();

	const sideBarIsOpen = useSelector(selectSideBarOpen);

	const [visibleColumns, setVisibleColumns] = useLocalStorage('HistoryColumns', defaultVisibleColumns);

	const {
		paginationData,
		setPage,
		setLimit,
		setSort,
		setSearch,
		setSearchKey,
		hasFilters,
		setDate,
		setEventType,
		setFleet,
		setTracker,
		setVehicle,
		queryId,
		setCompany
	} = useHistoryPaginationQuery<THistoryPosition>({
		availableSearchKeys: columnsSearchKeys,
		availableSortKeys,
		onQueryHasId: () => dispatch(setSideBarOpen(true))
	});

	const permissions = usePermission({
		pagePermissions: HISTORY_PERMISSIONS
	});

	const { history, totalItems, selectedRow, isLoading, isFetching } = useGetAllHistoryQuery(paginationData, {
		selectFromResult: ({ data, ...res }) => ({
			history: data?.docs || emptyArr,
			selectedRow: _.find(data?.docs, { _id: queryId }) as THistoryPosition,
			totalItems: data?.totalDocs || 0,
			...res
		}),
		skip: !permissions.hasList || !hasFilters || !permissions.hasMinimal
	});

	const { trackerEvents, isTrackerEventsLoading } = useGetTrackerEventsQuery('', {
		selectFromResult: ({ data, ...res }) => ({
			trackerEvents: data?.docs || emptyArr,
			isTrackerEventsLoading: _.some([res.isLoading, res.isFetching]),
			...res
		})
	});

	const columns = useMemo(() => getColumns(t, visibleColumns, trackerEvents), [t, visibleColumns, trackerEvents]);

	const handleChangeFilter = (searchKey: string, columns: string[]) => {
		setSearchKey(searchKey);
		setVisibleColumns(columns);
	};

	return (
		<FusePageSimple
			header={
				permissions.hasList ? (
					<Header
						onChangeCompany={setCompany}
						totalDocs={totalItems}
						onChangeSearch={setSearch}
						onChangeFilter={handleChangeFilter}
						defaultValue={paginationData.search}
						currentSearchKey={paginationData.searchKey}
						visibleColumns={visibleColumns}
						loading={isLoading}
						paginationData={paginationData}
						onChangeDate={setDate}
						onChangeEventType={setEventType}
						onChangeFleet={setFleet}
						onChangeTracker={setTracker}
						onChangeVehicle={setVehicle}
						hasFilters={hasFilters}
					/>
				) : undefined
			}
			content={
				permissions.hasList ? (
					<Content
						onChangeLimit={setLimit}
						onChangePage={setPage}
						onChangeSort={setSort}
						columns={columns}
						loading={isFetching || isTrackerEventsLoading}
						data={history}
						totalRows={totalItems}
						paginationData={paginationData}
						hasFilters={hasFilters}
					/>
				) : (
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%'
						}}
					>
						<NoPermission imageSrc="/assets/images/bg/table_blur.png" t={t} />
					</Box>
				)
			}
			scroll="content"
			rightSidebarWidth={428}
			rightSidebarOpen={sideBarIsOpen}
			rightSidebarContent={
				permissions.hasView ? (
					<Sidebar history={selectedRow} />
				) : (
					<NoPermission t={t} imageSrc="/assets/images/bg/sidebar_blur.png" />
				)
			}
		/>
	);
}
