const dialog = {
	DATA: 'Dados',
	SEND_TO: 'Enviar para',
	SEND_FROM: 'Enviar de',
	CONTINUE: 'Continuar',
	ADD_RULE: 'Adicionar regra',

	COMPANIES_LIMIT: '{{n}} empresas selecionadas',
	USERS_LIMIT: '{{n}} usuários selecionados',
	FLEETS_LIMIT: '{{n}} frotas selecionadas',
	VEHICLES_LIMIT: '{{n}} veículos selecionados',

	SELECT_NAME_AND_COMPANY_HEADER: 'Selecione o nome e a empresa da regra',
	SEND_NOTIFIACTION_TO_HEADER: 'Selecione as empresas ou usuários que receberão a notificação',
	SEND_NOTIFIACTION_FROM_HEADER: 'Selecione as empresas, frotas ou veículos dos quais a notificação será enviada',
	RULES_HEADER: 'Adicione as regras que serão utilizadas para enviar a notificação',
	FREQUENCY_HEADER: 'Selecione o período que deseja receber a notificação',
	REVIEW_HEADER: 'Revise as informações da notificação',
	ADD_COMMAND_HEADER: 'Adicione um comando para ser executado quando a(s) regra(s) for atingida',

	YOUR_NOTIFICATION_WILL_BE_SENT: 'Sua notificação será enviada',
	SENT_NOTIFICATION: 'Nome de identificação',

	START_MONTH: 'Mês de início',
	END_MONTH: 'Mês de fim',
	START_WEEKDAY: 'Dia da semana de início',
	END_WEEKDAY: 'Dia da semana de fim',
	START_TIME: 'Horário de início',
	END_TIME: 'Horário de fim',
	FINISH: 'Finalizar',
	CLOSE: 'Fechar',
	SENT_TO: 'Enviar para',
	SEND_TO_COMPANIES: 'Enviar para empresas',
	SEND_TO_USERS: 'Enviar para usuários',
	COMMAND: 'Comando',
	COMMANDS: 'Comandos',
	NO_COMMANDS: 'Adicione um novo comando clicando no botão +',
	ACTIVE_AFTER: 'Ativar após',
	UNIT: 'Unidade',
	NO_DATA_FOUND: 'Nenhum dado encontrado',

	SECONDS: 'Segundos',
	MINUTES: 'Minutos',
	HOURS: 'Horas',
	DAYS: 'Dias',

	AT_LEAST_ONE_TARGET_REQUIRED: 'É necessário selecionar ao menos um item',
	REQUIRED_VALUE: 'É necessário adicionar um valor',
	REQUIRED_UNIT: 'É necessário selecionar uma unidade',
	COMMAND_REQUIRED: 'É necessário selecionar um comando'
};

const cronDescription = {
	BETWEEN: 'entre {{startRange}} e {{endRange}}',

	MONTH_OF_YEAR: 'No mês de {{month}}',

	AT_MINUTE_BETWEEN: 'a cada minuto entre {{start}} e {{end}}',
	AT_HOUR_BETWEEN: 'a cada hora entre {{start}} e {{end}}',
	AT_MINUTE: 'a cada {{minute}} minuto',
	AT_HOUR: 'a cada {{hour}} hora',
	AT_DAY: 'entre os dias {{start}} e {{end}}',
	AT_MONTH: 'entre os meses {{start}} e {{end}}',
	AT_WEEK: 'entre os dias da semana {{start}} e {{end}}',
	IMMEDIATELY: 'imediatamente',

	JANUARY: 'Janeiro',
	FEBRUARY: 'Fevereiro',
	MARCH: 'Março',
	APRIL: 'Abril',
	MAY: 'Maio',
	JUNE: 'Junho',
	JULY: 'Julho',
	AUGUST: 'Agosto',
	SEPTEMBER: 'Setembro',
	OCTOBER: 'Outubro',
	NOVEMBER: 'Novembro',
	DECEMBER: 'Dezembro',

	SUNDAY: 'Domingo',
	MONDAY: 'Segunda-feira',
	TUESDAY: 'Terça-feira',
	WEDNESDAY: 'Quarta-feira',
	THURSDAY: 'Quinta-feira',
	FRIDAY: 'Sexta-feira',
	SATURDAY: 'Sábado'
};

const view = {
	BACK: 'Voltar',
	FROM: 'De',
	TO: 'Até',
	DAYS: 'Dias',
	MONTHS: 'Meses',
	WEEKDAYS: 'Dias da semana',
	YEARS: 'Anos',
	RULER_DETAILS: 'Detalhes da regra',
	INVALID: 'Inválido',
	NO_COMMAND_FOUND: 'Nenhum comando',

	DELETE_SUCCESS: 'Regra excluída com sucesso',
	DELETE_MODAL_TITLE: 'Deletar regra',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar esta regra?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} regras?',

	IGNITION: 'Ignição',
	POSITION_IN: 'Posição em',
	CONTACT: 'Contato',
	UNTRACKED: 'Desconhecido',

	ALL_DAYS: 'Todos os dias',
	ALL_MONTHS: 'Todos os meses',
	ALL_WEEKDAYS: 'Todos os dias da semana',

	OF_VEHICLES: 'Notificação de veículos',
	OF_FLEETS: 'Notificação de frotas',
	OF_COMPANIES: 'Notificação de empresas',

	CREATED_BY: 'Criado por',
	CREATED_AT: 'Criado em',

	EDIT_INFO: 'Editar informações',
	DELETE: 'Excluir',

	YES: 'Sim',
	NO: 'Não'
};

const aggr = {
	AGGREGATE: 'Agrupar notificações',

	HELPER_AGGREGATE: 'Agrupa a notificação pela quantiadade de vezes que a regra foi atingida',
	HELPER_AGGREGATE_NAME: '"Nome da regra"',
	HELPER_AGGREGATE_RULE: 'atingida 6 vezes entre 17:56 e 18:00'
};

const locale = {
	...view,
	...dialog,
	...cronDescription,
	SELECTED_ITENS: '{{n}} Itens selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar itens selecionadas',
	DOWNLOAD_INFO: 'Baixar itens selecionados',
	NAME: 'Nome',
	TITLE: 'Regras',
	NOTIFICATIONS_RULER: 'Regras',
	REGISTER_NOTIFICATIONS: 'Cadastrar notificações',
	EDIT_NOTIFICATION: 'Editar notificação',
	REGISTER_NOTIFICATION: 'Cadastrar notificação',
	VIEW_NOTIFICATION: 'Visualizar notificação',

	dateStr: 'pt-BR',

	NO_DATA: 'Nenhum dado encontrado',
	LOADING: 'Carregando...',
	ERROR_GETTING_DATA: 'Erro ao obter dados',

	HELPER_SEND_TO_MONITORING: 'Envia a regra para o monitoramento',
	SEND_TO_MONITORING_TOOLTIP: 'Adiciona um novo alerta à tela de monitoramento ao atingir as regras',

	TARGET_TYPE: 'Mostrar notificação de',
	TARGET_SEND: 'Enviar notificação para',
	NOTIFICATION_WEB: 'Notificações no navegador',
	NOTIFICATION_TYPE: 'Tipo de notificação',
	NOTIFICATION_APP: 'Notificações no app',

	SELECT_TIME: 'Selecione o período',
	PERIOD: 'Período',
	NO_PERIOD: 'Adicione um novo período clicando no botão +',
	START: 'Início',
	END: 'Fim',
	CUSTOM_PERIOD: 'Período personalizado',
	MONTH: 'Mês',
	DAY: 'Dia',
	HOUR: 'Hora',
	WEEKDAYS: 'Dias da semana',

	MONDAY: 'Segunda-feira',
	TUESDAY: 'Terça-feira',
	WEDNESDAY: 'Quarta-feira',
	THURSDAY: 'Quinta-feira',
	FRIDAY: 'Sexta-feira',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',

	VEHICLE: 'Rastreado',
	VEHICLES: 'Veículos',
	FLEETS: 'Frotas',
	COMPANIES: 'Empresas',
	COMPANY: 'Empresa',
	USERS: 'Usuários',

	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',

	RULES: 'Regras',
	RULE: 'Regra',
	NO_RULES: 'Adicione uma nova regra clicando no botão +',

	TYPE: 'Tipo',
	EVENT: 'Evento',

	OPERATOR: 'Operador',
	EQUAL: 'Igual',
	NOT_EQUAL: 'Diferente',
	GREATER_THAN: 'Maior que',
	LESS_THAN: 'Menor que',

	VARIABLE: 'Variável',
	SPEED: 'Velocidade',
	RPM: 'Rotacões por minuto',
	IGNITION_ON: 'Estado da ignição',
	GEOFENCE: 'Cerca virtual',

	VALUE: 'Valor',
	IN: 'Entrada',
	OUT: 'Saída',
	ON: 'Ligada',
	OFF: 'Desligada',

	SEND_TO_MONITORING: 'Enviar para monitoramento',
	...aggr,

	NAME_REQUIRED: 'O campo nome é obrigatório',
	TARGET_REQUIRED: 'É necessário adicionar ao menos um receptor da notificação',
	NOTIFICATION_TYPE_REQUIRED: 'É necessário selecionar o tipo de notificação',
	RULE_REQUIRED: 'É necessário adicionar ao menos uma regra',
	PERIOD_REQUIRED: 'É necessário selecionar o período',
	COMPANY_REQUIRED: 'É necessário selecionar a empresa',
	TYPE_REQUIRED: 'É necessário selecionar o tipo de regra',
	VARIABLE_REQUIRED: 'É necessário selecionar a variável da regra',
	OPERATOR_REQUIRED: 'É necessário selecionar um operador',
	VALUE_REQUIRED: 'É adicionar um valor à regra',
	HOURS_REQUIRED: 'É necessário selecionar o horário de início e fim',
	START_END_DIFF: 'O horário de início deve ser menor que o horário de fim',

	CREATE_SUCCESS: 'Regra de notificação criada com sucesso',
	CREATE_ERROR: 'Erro ao criar regra de notificação',
	EDIT_SUCCESS: 'Regra de notificação editada com sucesso',
	EDIT_ERROR: 'Erro ao editar regra de notificação',

	SAVE: 'Salvar',
	CANCEL: 'Cancelar',
	CLOSE: 'Fechar',
	NEXT: 'Próximo',
	FINISH: 'Finalizar',
	AT_LEAST_ONE_TARGET: 'É necessário selecionar ao menos um item',

	GET_TRACKER_COMMANDS_ERROR: 'Erro ao obter comandos do rastreador',
	GET_GEOFENCES_ERROR: 'Erro ao obter cercas virtuais',

	CONTACTS: 'Contatos',
	CONTACTS_LIMIT: '{{n}} contatos selecionados',
	GET_CONTACTS_ERROR: 'Erro ao obter contatos',

	PAGE_NOT_ALLOWED: 'Recurso não autorizado.',
	PAGE_NOT_ALLOWED_DESCRIPTION: 'Você não tem as permissões necessárias para acessar esta página.',
	MISSING_PERMISSIONS: 'Permissões necessárias: {{permission}}',
	ALL_USERS_OF: 'Todos os usuários de',
	TARGETS: 'Alvos',
	ALL_TRACKERS_OF: 'Todos os rastreadores de',
	ALL_FLEETS_OF: 'Todos os veículos da frota',
	
};

export default locale;
