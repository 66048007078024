import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Box, Stack } from '@mui/material';
import { createDownloadLink, parseToCSV } from 'app/contexts/DownloadContext/helper';
import { useDownload } from 'app/services/hooks';
import TooltipIconButton from 'app/shared-components/Buttons/TooltipIconButton';
import FilterDialog from 'app/shared-components/CRUDs/Header/FilterDialog';
import SearchInput from 'app/shared-components/CRUDs/Header/SearchInput';
import SkeletonHeader from 'app/shared-components/CRUDs/Header/SkeletonHeader';
import DownloadWarning from 'app/shared-components/DownloadWarning/DownloadWarning';
import { useGetTrackerEventsQuery } from 'app/store/api/apiSlice';
import { TPaginatedQueryHistory } from 'app/store/api/historySlice';
import { closeDialog, openDialog, selectFuseDialogState } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectSelectedRows } from 'app/store/reports/historySlice';
import dayjs from 'dayjs';
import xlsx, { IJsonSheet } from 'json-as-xlsx';
import _ from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { objectToStringParams } from 'src/utils/objectToStringParams';
import { stringNow } from 'src/utils/stringNow';
import { MAPS_URL, getExcelColumns } from '../helper';
import { getColumnsNames, getSearchKeysNames } from '../tableColumns';
import { THeaderProps, THistoryPosition } from '../types';
import CustomDescriptionTag from './CustomDescriptionTag';
import HistoryFilter from './HistoryFilter/HistoryFilter';
import { TFormData } from './HistoryFilter/filterSchema';
import StreetView from './StreetView/StreetView';

function getDownloadColumns(t, excelColumns, visibleColumns, columnsNames) {
	const visibleColumnsNames = columnsNames.map((column) => visibleColumns.includes(column.columnId) && column.name);
	return excelColumns.filter((column) => visibleColumnsNames.includes(column.label));
}

export default function Header({
	onChangeSearch,
	onChangeFilter,
	visibleColumns,
	currentSearchKey,
	defaultValue,
	loading,
	paginationData,
	onChangeDate,
	onChangeEventType,
	onChangeFleet,
	onChangeTracker,
	onChangeVehicle,
	onChangeCompany,
	hasFilters,
	totalDocs
}: THeaderProps) {
	const { t } = useTranslation(['historyPage', 'Header']);
	const dispatch = useDispatch();
	const columnsNames = getColumnsNames(t);
	const searchKeysNames = getSearchKeysNames(t);
	const selectedRows = useSelector(selectSelectedRows);

	const { download, loading: isDownloading } = useDownload();
	const { trackerEvents } = useGetTrackerEventsQuery('', {
		selectFromResult: ({ data, ...res }) => ({
			trackerEvents: data?.docs ?? [],
			...res
		})
	});
	const downloadColumns = getDownloadColumns(
		t,
		getExcelColumns(t, trackerEvents),
		visibleColumns,
		getColumnsNames(t)
	);

	const handleApplyFilter = useCallback(
		(values: TFormData) => {
			const { start, end, eventType, fleet, tracker, vehicle, company } = values;

			onChangeDate(dayjs(start), 'start');
			onChangeDate(dayjs(end), 'end');

			const mappedEventType = eventType.map((item) => item._id).join(',');
			onChangeEventType(mappedEventType);

			const mappedFleet = fleet.map((item) => item._id).join(',');
			onChangeFleet(mappedFleet);

			const mappedTracker = tracker.map((item) => item._id).join(',');
			onChangeTracker(mappedTracker);

			const mappedVehicle = vehicle.map((item) => item._id).join(',');
			onChangeVehicle(mappedVehicle);

			const mappedCompany = company.map((item) => item._id).join(',');
			onChangeCompany(mappedCompany);

			dispatch(closeDialog());
		},
		[dispatch, onChangeDate, onChangeEventType, onChangeFleet, onChangeTracker, onChangeVehicle, onChangeCompany]
	);

	const handleHistoryFilterOpen = () => {
		dispatch(
			openDialog({
				children: <HistoryFilter onApply={handleApplyFilter} paginationData={paginationData} />
			})
		);
	};

	const handleFilterOpen = () =>
		dispatch(
			openDialog({
				children: (
					<FilterDialog
						columns={columnsNames}
						searchKeys={searchKeysNames}
						currentSelectedColumns={visibleColumns}
						currentSearchKey={currentSearchKey}
						handleApply={onChangeFilter}
						t={t}
					/>
				)
			})
		);

	const handleDownload = () => {
		try {
			const csv = parseToCSV({
				data: selectedRows,
				csvHeaders: visibleColumns
			});

			createDownloadLink(csv, stringNow('history'));
		} catch {
			dispatch(
				showMessage({
					message: t('DOWNLOAD_ERROR'),
					variant: 'error'
				})
			);
		}
	};

	const handleStreetView = () => {
		let mapsUrl = MAPS_URL;

		const cords = _.map(
			_.sortBy(selectedRows, (item) => dayjs(item.GPS_TIME).unix()),
			(item) => ({
				lat: item.LATITUDE,
				lon: item.LONGITUDE,
				date: item.GPS_TIME
			})
		);

		const isOver24Points = _.size(cords) > 24;

		_.forEach(cords, (item, index) => {
			if (index < 24) {
				mapsUrl += `${item.lat},${item.lon}/`;
			}
		});

		if (isOver24Points) {
			dispatch(
				openDialog({
					children: <StreetView cords={cords} />
				})
			);
		} else {
			window.open(mapsUrl, '_blank');
		}
	};

	const handleDownloadAll = () => {
		dispatch(
			openDialog({
				children: <DownloadWarning loading={isDownloading} onConfirm={handleConfirmDownload} />
			})
		);
	};

	const handleConfirmDownload = async () => {
		if (isDownloading) return;

		const newParams: Partial<TPaginatedQueryHistory> = _.omitBy(
			{
				start: _.get(paginationData, 'start', ''),
				end: _.get(paginationData, 'end', ''),
				vehicle: _.get(paginationData, 'vehicle', ''),
				eventType: _.get(paginationData, 'eventType', ''),
				tracker: _.get(paginationData, 'tracker', ''),
				fleet: _.get(paginationData, 'fleet', '')
			},
			_.isEmpty
		);

		const stringParams = objectToStringParams(newParams);

		dispatch(closeDialog());

		const historyData = (await download({
			url: `/report/history`,
			params: stringParams
		})) as THistoryPosition[];

		const sheet: IJsonSheet = {
			sheet: 'history_report',
			columns: downloadColumns,
			content: historyData
		};

		const settings = {
			fileName: stringNow('history')
		};

		xlsx([sheet], settings);
	};

	const isFilterOpened = useSelector(selectFuseDialogState);
	const isFirstRender = useRef(true);
	const hasRowsSelected = _.size(selectedRows) > 0;

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			if (!isFilterOpened && !hasFilters) {
				dispatch(
					openDialog({
						children: <HistoryFilter onApply={handleApplyFilter} paginationData={paginationData} />
					})
				);
			}
		}
	}, [dispatch, handleApplyFilter, hasFilters, isFilterOpened, paginationData]);

	if (loading) return <SkeletonHeader />;

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: ['flex-start', 'center'],
				flexDirection: ['column', 'row'],
				paddingY: ['18px', '36px'],
				paddingX: ['20px', '40px'],
				width: '100%',
				gap: ['8px', '0px']
			}}
		>
			<CustomDescriptionTag title={t('TITLE')} />

			<Stack spacing={2} direction="row" alignItems="center">
				<TooltipIconButton
					iconButtonProps={{ disabled: !hasFilters || !totalDocs, color: 'secondary' }}
					label={t('DOWNLOAD_ALL')}
					icon="file_download"
					size="small"
					onClick={handleDownloadAll}
				/>

				<Badge badgeContent={hasRowsSelected ? _.size(selectedRows) : undefined} color="secondary">
					<TooltipIconButton
						iconButtonProps={{ disabled: !hasRowsSelected, color: 'secondary' }}
						label={t('DOWNLOAD_SELECTED')}
						icon="download"
						size="small"
						onClick={handleDownload}
					/>
				</Badge>

				<TooltipIconButton
					iconButtonProps={{ disabled: !hasRowsSelected, color: 'secondary' }}
					label="Google Maps"
					icon="map"
					size="small"
					onClick={handleStreetView}
				/>

				<TooltipIconButton
					iconButtonProps={{ color: 'secondary' }}
					label={t('SELECT_FILTERS')}
					icon="settings"
					size="small"
					onClick={handleHistoryFilterOpen}
				/>

				<SearchInput
					defaultValue={defaultValue}
					onOpenFilter={handleFilterOpen}
					handleChange={onChangeSearch}
				/>
			</Stack>
		</Box>
	);
}
