import { useDeepCompareEffect } from '@fuse/hooks';
import { TVehicle } from 'app/main/vehicle/types';
import HookedVirtualizedChipSelect from 'app/shared-components/Hooked/HookedVirtualizedChipSelect';
import { useGetAllVehiclesByCompaniesQuery } from 'app/store/api/vehiclesSlice';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { splitAndGetOption } from '../../../helper';
import { TFormData } from '../filterSchema';

interface VehiclesSelectProps {
	initialValue: string;
	sx?: {};
	className?: string;
	fullWidth?: boolean;
	noHelperText?: boolean;
}

export const VehiclesSelect: React.FC<VehiclesSelectProps> = ({ initialValue, sx, fullWidth = true, className, noHelperText }) => {

	const { t } = useTranslation('eventsReportPage');
	const { control, setValue, watch } = useFormContext<TFormData>();

	const selectedCompanies = watch('company');
	const hasCompanySelected = !_.isEmpty(selectedCompanies);
	const companiesIds = _.map(selectedCompanies, '_id');
	const skip = !hasCompanySelected;

	const { vehicles, isVehiclesLoading } = useGetAllVehiclesByCompaniesQuery(companiesIds, {
		selectFromResult: ({ data, ...res }) => ({
			vehicles: data?.docs || [],
			isVehiclesLoading: res.isLoading
		}),
		skip
	});

	useDeepCompareEffect(() => {
		if (!_.isEmpty(vehicles) && initialValue) {
			setValue('vehicle', splitAndGetOption(initialValue, vehicles));
		}
	}, [vehicles, initialValue]);

	return (
		<HookedVirtualizedChipSelect<TFormData, TVehicle>
			fullWidth={fullWidth}
			className={className}
			sx={sx}
			options={vehicles}
			control={control}
			name="vehicle"
			label={t('VEHICLES')}
			optionLabel="name"
			t={t}
			loading={isVehiclesLoading}
			isDisabled={!hasCompanySelected}
			limitText={(n) => t('VEHICLES_SELECTED', { n })}
			maxListHeight={200}
			noHelperText={noHelperText}
		/>
	);
};
