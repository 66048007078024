import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { createSearchParams, useLocation, useParams, useSearchParams } from 'react-router-dom';

import { TPaginatedQueryHistory } from 'app/store/api/historySlice';
import dayjs, { Dayjs } from 'dayjs';

const validateSearchKeys = (fromQuery: string, availableColumns: string[]) => {
	if (!fromQuery) return '';
	return fromQuery
		.split(',')
		.filter((o) => availableColumns.includes(o))
		.join();
};

const validateSortKey = (fromQuery: string, availableColumns: string[]) => {
	if (!fromQuery || !availableColumns.includes(fromQuery)) return '';
	return fromQuery;
};

const validateSortValue = (fromQuery: string): SortOrder => {
	if (fromQuery === 'asc' || fromQuery === 'desc') {
		return fromQuery as SortOrder;
	}
	return 'desc' as SortOrder;
};

export const validateDate = (fromQuery: string) => {
	if (!fromQuery) return '';

	if (dayjs(fromQuery).isValid()) {
		return fromQuery;
	}

	return '';
};

const defaultQuerySearch = {
	page: 1,
	limit: 100,
	search: '',
	searchKey: [],
	sortKey: '',
	sortValue: 'desc',
	tracker: '',
	fleet: '',
	vehicle: '',
	eventType: '',
	start: '',
	end: '',
	company: ''
};

type TUseHistoryPaginationQuery = {
	availableSortKeys: string[];
	availableSearchKeys: string[];
	onQueryHasId?: (id: string) => void;
};

function useHistoryPaginationQuery<T>({
	availableSearchKeys = [],
	availableSortKeys = [],
	onQueryHasId
}: TUseHistoryPaginationQuery) {
	const [, setSearchParams] = useSearchParams({ page: '1', limit: '100' });
	const { search } = useLocation();
	const { id } = useParams();

	const paginationData = useMemo((): TPaginatedQueryHistory => {
		const searchParams = createSearchParams(search);

		const limit = _.parseInt(searchParams.get('limit'));
		const page = _.parseInt(searchParams.get('page'));

		const sortKey = validateSortKey(searchParams.get('sortKey'), availableSortKeys);
		const sortValue = validateSortValue(searchParams.get('sortValue'));
		const validSearchKeys = validateSearchKeys(searchParams.get('searchKey'), availableSearchKeys);

		const tracker = searchParams.get('tracker');
		const fleet = searchParams.get('fleet');
		const vehicle = searchParams.get('vehicle');
		const eventType = searchParams.get('eventType');
		const company = searchParams.get('company');

		const start = validateDate(searchParams.get('start'));
		const end = validateDate(searchParams.get('end'));

		return {
			limit: _.isNaN(limit) ? defaultQuerySearch.limit : limit,
			page: _.isNaN(page) ? defaultQuerySearch.page : page,
			search: searchParams.get('search') ?? defaultQuerySearch.search,
			searchKey: validSearchKeys,
			sortKey: sortKey ?? defaultQuerySearch.sortKey,
			sortValue,
			tracker,
			fleet,
			vehicle,
			eventType,
			company,
			start,
			end
		};
	}, [search, availableSearchKeys, availableSortKeys]);

	const hasFilters = useMemo(() => {
		const { tracker, fleet, vehicle, company, eventType, start, end } = paginationData;

		return !!(tracker || fleet || vehicle || company || eventType || start || end);
	}, [paginationData]);

	const handleCompanyChange = useCallback(
		(newCompany: string) => {
			setSearchParams((prev) => {
				if (newCompany) {
					prev.set('company', newCompany);
				} else {
					prev.delete('company');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handlePageChange = useCallback(
		(newPage: number) => {
			setSearchParams((prev) => {
				if (newPage) prev.set('page', `${newPage}`);
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleLimitChange = useCallback(
		(newLimit: number) => {
			setSearchParams((prev) => {
				if (newLimit) prev.set('limit', `${newLimit}`);
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleSearchChange = useCallback(
		(newSearch: string) => {
			setSearchParams((prev) => {
				if (newSearch) {
					prev.set('search', newSearch);
				} else {
					prev.delete('search');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleSearchKeyChange = useCallback(
		(newSearchKey: string) => {
			setSearchParams((prev) => {
				if (newSearchKey) {
					prev.set('searchKey', newSearchKey);
				} else {
					prev.delete('searchKey');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleSortChange = useCallback(
		(selectedColumn: TableColumn<T>, sortDirection: SortOrder) => {
			setSearchParams((prev) => {
				if (selectedColumn) {
					prev.set('sortKey', `${selectedColumn.id}`);
				} else {
					prev.delete('sortKey');
				}
				if (sortDirection) {
					prev.set('sortValue', sortDirection);
				} else {
					prev.delete('sortValue');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleDateChange = useCallback(
		(newDate: Dayjs, type: 'start' | 'end') => {
			setSearchParams((prev) => {
				if (newDate) {
					prev.set(type, newDate.toISOString());
				} else {
					prev.delete(type);
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleTrackerChange = useCallback(
		(newTracker: string) => {
			setSearchParams((prev) => {
				if (newTracker) {
					prev.set('tracker', newTracker);
				} else {
					prev.delete('tracker');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleFleetChange = useCallback(
		(newFleet: string) => {
			setSearchParams((prev) => {
				if (newFleet) {
					prev.set('fleet', newFleet);
				} else {
					prev.delete('fleet');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleVehicleChange = useCallback(
		(newVehicle: string) => {
			setSearchParams((prev) => {
				if (newVehicle) {
					prev.set('vehicle', newVehicle);
				} else {
					prev.delete('vehicle');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleEventTypeChange = useCallback(
		(newEventType: string) => {
			setSearchParams((prev) => {
				if (newEventType) {
					prev.set('eventType', newEventType);
				} else {
					prev.delete('eventType');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	useEffect(() => {
		if (onQueryHasId && id) {
			onQueryHasId(id);
		}
	}, [id, onQueryHasId]);

	return {
		paginationData,
		queryId: id,
		setPage: handlePageChange,
		setSearch: handleSearchChange,
		setLimit: handleLimitChange,
		setSearchKey: handleSearchKeyChange,
		setSort: handleSortChange,
		setDate: handleDateChange,
		setTracker: handleTrackerChange,
		setFleet: handleFleetChange,
		setVehicle: handleVehicleChange,
		setEventType: handleEventTypeChange,
		setCompany: handleCompanyChange,
		hasFilters
	};
}

export default useHistoryPaginationQuery;
