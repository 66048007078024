import type { THistoryPosition } from 'app/main/reports/historyNew/types';
import { isEmpty } from 'lodash';
import { apiSlice } from './apiSlice';
import type { IApiPagination } from './types';

export type TPaginatedQueryHistory = {
	limit: number;
	page: number;
	search: string;
	searchKey: string;
	sortKey: string;
	sortValue: string;
	company: string;
	tracker: string;
	fleet: string;
	vehicle: string;
	eventType: string;
	start: string;
	end: string;
	onlyEvents?: boolean;
};

const getStringQuery = (paginatedData: TPaginatedQueryHistory, url: string) => {
	const {
		page,
		limit,
		search,
		sortKey,
		sortValue,
		end,
		eventType,
		fleet,
		searchKey,
		start,
		tracker,
		vehicle,
		onlyEvents
	} = paginatedData;

	let query = `${url}?page=${page}&limit=${limit}`;

	if (search) {
		query += `&search=${search}`;
	}

	if (!isEmpty(sortKey) && !isEmpty(sortValue)) {
		query += `&sortKey=${sortKey}&sortValue=${sortValue}`;
	}

	if (end) {
		query += `&end=${end}`;
	}

	if (start) {
		query += `&start=${start}`;
	}

	if (eventType) {
		query += `&eventType=${eventType}`;
	}

	if (fleet) {
		query += `&fleet=${fleet}`;
	}

	if (searchKey) {
		query += `&searchKey=${searchKey}`;
	}
	if (tracker) {
		query += `&tracker=${tracker}`;
	}

	if (vehicle) {
		query += `&vehicle=${vehicle}`;
	}

	if (onlyEvents) {
		query += `&onlyEvents=${onlyEvents}`;
	}

	return query;
};

export interface IGetVehicleHistory {
	vehicle: string;
	start: string;
	end: string;
}

const historySlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllHistory: builder.query<IApiPagination<THistoryPosition>, TPaginatedQueryHistory>({
			query: (query) => ({
				url: getStringQuery(query, 'report/history'),
				method: 'GET'
			}),
			providesTags: ['History']
		}),
		getVehicleHistory: builder.query<IApiPagination<THistoryPosition>, IGetVehicleHistory>({
			query: ({ vehicle, start, end }) => ({
				url: `report/history?vehicle=${vehicle}&start=${start}&end=${end}&limit=0`,
				method: 'GET'
			}),
			providesTags: ['History']
		})
	})
});

export const { useGetAllHistoryQuery, useLazyGetAllHistoryQuery, useLazyGetVehicleHistoryQuery } = historySlice;

export default historySlice;