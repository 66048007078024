import { ClearAllOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IResetButtonProps {
	handleReset: () => void;
}

export default function ResetButton({ handleReset, children }: PropsWithChildren<IResetButtonProps>) {
	return (
		<Button
			sx={{ alignSelf: 'flex-end' }}
			variant="text"
			color="error"
			endIcon={<ClearAllOutlined />}
			onClick={handleReset}
			size="small"
		>
			{children}
		</Button>
	);
}
