import { WarningOutlined } from '@mui/icons-material';
import { Box, DialogContent, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';
import { HISTORY_PERMISSIONS } from '../../HistoryConfig';

interface NoPermissionFilterProps {
	t: TFunction;
}

export default function NoPermissionFilter({ t }: NoPermissionFilterProps) {
	const minialPermissionsText = HISTORY_PERMISSIONS.minimal.map((permission) => t(permission)).join(', ');

	return (
		<DialogContent sx={{ maxHeight: '630px', maxWidth: '380px' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%'
				}}
			>
				<WarningOutlined color="error" sx={{ fontSize: '80px' }} />
				<Typography variant="h6" sx={{ marginTop: '20px' }} align="center">
					{t('NO_FILTER_PERMISSION')}
				</Typography>
				<Typography variant="subtitle2" sx={{ marginTop: '20px' }} align="center">
					{t('NO_FILTER_PERMISSION_DESCRIPTION')}
				</Typography>
				<Typography variant="caption" color="text.secondary" sx={{ marginTop: '20px' }} align="center">
					{minialPermissionsText}
				</Typography>
			</Box>
		</DialogContent>
	);
}
