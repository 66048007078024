import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePermission from 'app/services/hooks/usePermission';
import AppButton from 'app/shared-components/Buttons/AppButton';
import ResetButton from 'app/shared-components/Buttons/ResetButton';
import DialogWrapper from 'app/shared-components/DialogWrapper/DialogWrapper';
import HookedDateTimePicker from 'app/shared-components/Hooked/HookedDateTimePicker';
import { TPaginatedQueryHistory } from 'app/store/api/historySlice';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch } from 'app/store/index';

import { HISTORY_PERMISSIONS } from '../../HistoryConfig';
import NoPermissionFilter from './NoPermissionFilter';
import { TFormData, filterSchema } from './filterSchema';
import { CompaniesSelect } from './selects/CompaniesSelect';
import { EventTypeSelect } from './selects/EventTypesSelect';
import { FleetSelect } from './selects/FleetsSelect';
import { TrackersSelect } from './selects/TrackersSelect';
import { VehiclesSelect } from './selects/VehiclesSelect';

interface HistoryFilterProps {
	paginationData: TPaginatedQueryHistory;
	onApply: (_data: TFormData) => void;
}

const clearedValues: TFormData = {
	search: '',
	tracker: [],
	fleet: [],
	vehicle: [],
	eventType: [],
	company: [],
	start: dayjs().hour(0).minute(0).second(0),
	end: dayjs().hour(23).minute(59).second(59)
};

export default function HistoryFilter({ onApply, paginationData }: HistoryFilterProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('historyPage');

	const permissions = usePermission({
		pagePermissions: HISTORY_PERMISSIONS
	});

	const methods = useForm<TFormData>({
		resolver: yupResolver(filterSchema),
		defaultValues: clearedValues,
		mode: 'all'
	});

	const { control, handleSubmit, reset } = methods;

	const onSubmit = handleSubmit(onApply);
	const handleCloseModal = () => dispatch(closeDialog());
	const handleReset = () => reset(clearedValues);

	if (!permissions.hasMinimal || !permissions.hasView) return <NoPermissionFilter t={t} />;

	return (
		<FormProvider {...methods}>
			<DialogWrapper
				title={t('FILTERS', { ns: 'Header' })}
				description={t('FILTERS_DESCRIPTION')}
				onClose={handleCloseModal}
				content={
					<Stack spacing={1} component="form" onSubmit={onSubmit} flex={1}>
						<CompaniesSelect initialValue={paginationData?.company} />

						<TrackersSelect initialValue={paginationData?.tracker} />

						<VehiclesSelect initialValue={paginationData?.vehicle} />

						<FleetSelect initialValue={paginationData?.fleet} />

						<EventTypeSelect initialValue={paginationData?.eventType} />

						<HookedDateTimePicker
							control={control}
							name="start"
							label={t('START_DATE')}
							t={t}
							disableFuture
						/>

						<HookedDateTimePicker control={control} name="end" label={t('END_DATE')} t={t} disableFuture />

						<Stack gap={2}>
							<ResetButton handleReset={handleReset}>{t('RESET')}</ResetButton>
							<AppButton fullWidth type="submit" variant="contained" color="secondary">
								{t('APPLY')}
							</AppButton>
						</Stack>
					</Stack>
				}
			/>
		</FormProvider>
	);
}
