import { DialogContent, Stack } from '@mui/material';
import AppButton from 'app/shared-components/Buttons/AppButton';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MAPS_URL } from '../../helper';
import StreetViewWarning from './StreetViewWarning';
import TripCard from './TripCard';

interface IStreetViewProps {
	cords: {
		lat: number;
		lon: number;
		date: Date;
	}[];
}

export default function StreetView({ cords }: IStreetViewProps) {
	const { t } = useTranslation('historyPage');
	const dispatch = useDispatch();

	const trips = _.chunk(cords, 24);

	const handleOpenStreetView = (cords: { lat: number; lon: number }[]) => {
		let mapsUrl = MAPS_URL;

		_.forEach(cords, (item) => {
			mapsUrl += `${item.lat},${item.lon}/`;
		});

		window.open(mapsUrl, '_blank');
	};

	const handleClose = () => dispatch(closeDialog());

	return (
		<DialogContent
			sx={{
				minWidth: '320px',
				maxWidth: '520px',
				maxHeight: 'calc(100vh - 200px)',
				overflowY: 'auto'
			}}
		>
			<Stack direction="column" sx={{ width: '100%' }} spacing={1}>
				<StreetViewWarning t={t} totalOfTrips={trips?.length} />
				{_.map(trips, (trip, index) => (
					<TripCard
						key={`trip-${index}`}
						title={`${t('TRIP', { n: index + 1 })} - ${dayjs(trip[0].date).format(
							'DD/MM/YYYY HH:mm'
						)} > ${dayjs(trip[_.size(trip) - 1].date).format('DD/MM/YYYY HH:mm')}`}
						onClick={() => handleOpenStreetView(trip)}
					/>
				))}

				<AppButton variant="outlined" color="error" onClick={handleClose}>
					{t('CLOSE')}
				</AppButton>
			</Stack>
		</DialogContent>
	);
}
