import { Skeleton } from '@mui/material';
import { Box, Stack } from '@mui/system';

export default function SkeletonHeader() {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: ['flex-start', 'center'],
				flexDirection: ['column', 'row'],
				paddingY: ['18px', '36px'],
				paddingX: ['20px', '40px'],
				width: '100%',
				gap: ['8px', '0px']
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: ['space-between', 'flex-start'],
					alignItems: ['center', 'flex-start'],
					flexDirection: ['row', 'column'],
					gap: ['0px', '8px'],
					width: ['100%', 'fit-content']
				}}
			>
				<Skeleton variant="rounded" width="150px" height="36px" />
				<Skeleton variant="rounded" width="150px" height="32px" />
			</Box>
			<Stack spacing={2} direction="row" alignItems="center">
				<Skeleton variant="rounded" width="254px" height="40px" sx={{ borderRadius: '999px' }} />
				<Skeleton variant="circular" width="40px" height="40px" />
			</Stack>
		</Box>
	);
}
