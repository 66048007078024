import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import _ from 'lodash';
import { UseControllerProps, useController } from 'react-hook-form';
import { TFunction } from 'react-i18next';

type TFormAndDatePicker<F> = UseControllerProps<F> &
	DatePickerProps<Dayjs | null> & { t: TFunction; required?: boolean; fullWidth?: boolean };

export default function HookedDatePicker<F>({
	control,
	name,
	label,
	t,
	format = 'DD/MM/YYYY',
	required = false,
	disableFuture = false,
	fullWidth = true,
	...props
}: TFormAndDatePicker<F>) {
	const {
		field,
		formState: { errors }
	} = useController({ name, control });
	const handleChange = (date: Dayjs | null) => {
		if (date !== null && date.isValid()) {
			field?.onChange(date);
		} else {
			field?.onChange(null);
		}
	};
	return (
		<DatePicker
			label={label}
			value={field?.value}
			onChange={handleChange}
			slotProps={{
				textField: {
					required,
					onBlur: field?.onBlur,
					helperText: _.has(errors, name) ? t(_.get(errors, [name, 'message'])) : undefined,
					error: _.has(errors, name),
					size: 'small',
					fullWidth,
					name,
					...(props.slotProps?.textField || {})
				}
			}}
			{...props}
			format={format}
			disableFuture={disableFuture}
		/>
	);
}
