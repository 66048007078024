import { createSlice } from '@reduxjs/toolkit';
import { THistoryPosition } from 'app/main/reports/historyNew/types';

interface HistoryState {
	visibleColumns: string[];
	sideBarOpen: boolean;
	selectedRows: THistoryPosition[];
}

const initialState: HistoryState = {
	sideBarOpen: false,
	selectedRows: []
};

const historySlice = createSlice({
	name: 'history',
	initialState,
	reducers: {
		setSideBarOpen(state, action) {
			state.sideBarOpen = action.payload;
		},
		setSelectedRows(state, action) {
			state.selectedRows = action.payload;
		}
	}
});

export const { setSideBarOpen, setSelectedRows } = historySlice.actions;

export const selectSideBarOpen = (state: { history: HistoryState }) => state.history.sideBarOpen;
export const selectSelectedRows = (state: { history: HistoryState }) => state.history.selectedRows;

export default historySlice.reducer;
